<template>
  <div class="container">
    <a-form-model style="margin-top: 3%" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" :form="form">
      <a-form-model-item label="产品型号">
        {{ form.identifier }}
      </a-form-model-item>
      <a-form-model-item label="产品名称">
        {{ form.name }}
      </a-form-model-item>
      <a-form-model-item label="关联物模型">
        <a-table :data-source="form.productDtos" size="middle" rowKey="productId">
          <a-table-column align="center" data-index="productId" key="productId" title="产品ID"></a-table-column>
          <a-table-column align="center" data-index="name" key="name" title="产品名称"></a-table-column>
          <a-table-column align="center" data-index="type" name="type" title="产品类型">
            <template slot-scope="scope">
              {{ productTypes(scope) }}
            </template>
          </a-table-column>
        </a-table>
      </a-form-model-item>
      <a-form-model-item label="更新人">
        {{ form.updaterName }}
      </a-form-model-item>
      <a-form-model-item label="更新时间">
        {{ moment(form.lastModificationTime).format('YYYY年MM月DD日 HH:mm:ss') }}
        <div>
          <a-button @click="$router.back()">返回</a-button>
        </div>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'ProductModelCreator',
  async activated() {
    await Promise.all([this.getMetaData(), this.getProductModel()]);
  },
  data() {
    return {
      form: {},
      metaData: {},
      moment,
    };
  },
  methods: {
    async getMetaData() {
      const response = await this.$apiManager.productMetaData.getMetaData();
      this.metaData = response.data;
    },
    getProductModel() {
      this.$apiManager.productModel.getProductModelById(this.$route.params.id).then((res) => {
        this.form = res.data;
      });
    },
  },
  computed: {
    productTypes() {
      return (type) => {
        const typeName = this.metaData.productTypes.find((md) => md.value === type);
        return typeName ? typeName.name : '';
      };
    },
  },
};
</script>
<style lang="less" scoped></style>
